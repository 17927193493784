import { LoadAnimatedContainer } from "../../../components/LoadAnimatedContainer";
import { ReactComponent as More } from "../../../assets/icons/more.svg";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";
import "react-quill/dist/quill.snow.css";
import UIPopover from "../../../components/Popover";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import { DeleteFormConfirm } from "../components/DeleteFormConfirm";
import { Button } from "../../../components/Button";
import { FormModal } from "../modals/FormModal";
import useForms, { Form } from "../hooks/useForms";
import { useAdminUsers } from "../../manageAdmin/context/AdminUsersContext";
import { AdminPermission } from "../../manageAdmin/AdminUserList.types";


export function FormList() {
  const { myPermissions } = useAdminUsers();
  const { forms, fetchForms } = useForms();

  const [editModal, setEditModal] = useState<Form | undefined>(undefined);
  const [removeConfirmModal, setRemoveConfirmModal] = useState<Form | undefined>(undefined);
  const [newFormModal, setNewFormModal] = useState(false);

  const stopPropagate = (e: React.SyntheticEvent) => e.stopPropagation();

  const handleNewProviderModal = () => {
    setNewFormModal(true);
  };

  const closeFormModal = () => {
    fetchForms();
    setNewFormModal(false);
    setEditModal(undefined);
  };

  useEffect(() => {
    fetchForms();
  }, []);

  const observerTarget = useRef(null);

  return (
    <>
      <LoadAnimatedContainer>
        <div className="mb-5 flex gap-5 flex-col md:flex-row justify-between md:items-center">
          <div className="text-black text-3xl font-bold">Manage Surveys</div>
          {myPermissions.includes(AdminPermission.WRITE_PAGE_MANAGE_BULLETIN) &&
            <div className="flex justify-center">
              <Button
                variant="primary"
                onClick={handleNewProviderModal}
                className="!rounded-full"
              >
                <PlusIcon className="[&_path]:stroke-white" />
                Add Survey
              </Button>
            </div>
          }
        </div>
        <div className="bg-white rounded-lg shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)] p-5">
          <div
            className={`mt-3 text-[14px] h-[calc(100vh-232px)] overflow-auto`}
          >
            <table className="w-full">
              <colgroup>
                <col style={{ width: "15%" }} />
                <col style={{ width: "45%" }} />
                <col style={{ width: "25%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "5%" }} />
              </colgroup>
              <thead className="[&_td]:bg-[#EEF1EF] [&_td]:py-4">
                <tr className="sticky top-0 z-[1]">
                  <td className="rounded-l pl-5">
                    <div className="flex items-center">Title</div>
                  </td>
                  <td>
                    <div className="flex items-center">URL</div>
                  </td>
                  <td>
                    <div className="flex items-center"># Completed</div>
                  </td>
                  <td>
                    <div
                      className="flex items-center"
                    >
                      Created On
                    </div>
                  </td>
                  <td className="rounded-r"></td>
                </tr>
              </thead>
              <tbody>
                {forms?.map((form) => (
                  <tr
                    key={form.id}
                    className="hover:bg-[#F9F9F9] [&_td]:py-3 border-b border-b-[#EFF1FF]"
                    onClick={() => myPermissions.includes(AdminPermission.WRITE_PAGE_MANAGE_BULLETIN) && setEditModal(form)}
                    style={myPermissions.includes(AdminPermission.WRITE_PAGE_MANAGE_BULLETIN) ? { cursor: "pointer" } : {}}
                  >
                    <td className="name pl-5">
                      <div className="flex items-center gap-2.5">
                        <h5 className="flex-1">
                          {form?.title}
                        </h5>
                      </div>
                    </td>
                    <td>
                      <a className="text-primary" href={form.url} target="_blank">{form.url}</a>
                    </td>
                    <td>
                      <div className="flex items-center gap-2">
                        {form?.completedBy?.length || 0} / {form?.assignedUsers?.length || 0}
                      </div>
                    </td>
                    <td className="mrr">
                      {moment.utc(form.createdAt).local().format("MMM DD,YYYY")}
                    </td>
                    <td onClick={stopPropagate} className="pr-5">
                      {myPermissions.includes(AdminPermission.WRITE_PAGE_MANAGE_FORMS) &&
                        <UIPopover
                          trigger={
                            <span className="block p-2 border border-gray-270 rounded-lg">
                              <More />
                            </span>
                          }
                          positions={["bottom", "top", "left", "right"]}
                        >
                          {(close) => (
                            <FormEntryDropdown
                              onRemove={() => {
                                close();
                                setRemoveConfirmModal(form);
                              }}
                              onEdit={() => {
                                close();
                                setEditModal(form);
                              }}
                            />
                          )}
                        </UIPopover>
                      }
                    </td>
                  </tr>
                ))}
                <div ref={observerTarget}></div>
                <div className="h-5"></div>
              </tbody>
            </table>
          </div>
        </div>
        {editModal && (
          <FormModal
            onClose={closeFormModal}
            existingForm={editModal}
            isOpen={!!editModal} />
        )}
        {removeConfirmModal && (
          <DeleteFormConfirm
            onCancel={() => setRemoveConfirmModal(undefined)}
            onSuccess={() => {
              fetchForms()
              setRemoveConfirmModal(undefined);
            }}
            form={removeConfirmModal}
          />
        )}
        {newFormModal && (
          <FormModal
            isOpen={newFormModal}
            onClose={closeFormModal}
          />
        )}
      </LoadAnimatedContainer>
    </>
  );
}

export const FormEntryDropdown = ({ onEdit, onRemove }) => {
  return (
    <div className="z-10 w-[150px] flex flex-col justify-center items-center bg-white shadow-[0_6px_24px_0_rgba(28,43,40,0.25)] rounded-xl py-2 mr-8">
      <ul
        className="py-1 text-sm text-gray-700 "
        aria-labelledby="dropdownMenuIconButton"
      >
        <li>
          <button
            className="w-full text-[#212424] px-5 h-10 text-base rounded-[10px] hover:bg-whiteButtonHover text-left"
            onClick={(e) => {
              e.stopPropagation();
              onEdit();
            }}
          >
            Edit Survey
          </button>
        </li>
        <li>
          <button
            className="w-full text-[#212424] px-5 h-10 text-base text-red-500 rounded-[10px] hover:bg-whiteButtonHover text-left"
            onClick={(e) => {
              e.stopPropagation();
              onRemove();
            }}
          >
            Delete Survey
          </button>
        </li>
      </ul>
    </div>
  );
};
