import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/icons/Logo.svg";
import { ReactComponent as CheckLogo } from "../../assets/icons/check-logo.svg";
import { ReactComponent as FolderIcon } from "../../assets/icons/folder.svg";
import { ReactComponent as MessageIcon } from "../../assets/icons/message.svg";
import { ReactComponent as BulletinIcon } from "../../assets/icons/bulletin.svg";
import { ReactComponent as SurveyIcon } from "../../assets/icons/survey.svg";
import { ReactComponent as PersonIcon } from "../../assets/icons/person.svg";
import { ReactComponent as More } from "../../assets/icons/more.svg";
import { ReactComponent as UsersIcon } from "../../assets/icons/users.svg";
import { ReactComponent as ReferralIcon } from "../../assets/icons/referral.svg";
import { ReactComponent as ListIcon } from "../../assets/icons/list.svg";
import { ReactComponent as ListTicksIcon } from "../../assets/icons/list-ticks.svg";
import { ReactComponent as DashboardIcon } from "../../assets/icons/dashboard.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/x.svg";
import { ReactComponent as LeftCollapseIcon } from "../../assets/icons/left-collapse.svg";
import { ReactComponent as RightCollapseIcon } from "../../assets/icons/right-collapse.svg";
import { useState } from "react";
import { AnimatePresence, transform } from "framer-motion";
import { UserButton } from "../UserButton/UserButton";
import { getUser } from "../../services/utils";
import { Dropdown } from "../Dropdown";
import { LogoutConfirm } from "./LogoutConfirm";
import { useUser } from "../../pages/auth/context/user-context";
import { SettingsModal } from "./SettingsModal";
import { useAdminUsers } from "../../pages/manageAdmin/context/AdminUsersContext";
import { AdminPermission } from "../../pages/manageAdmin/AdminUserList.types";
import { ChangePassword } from "../../pages/provider/profile/ChangePassword";
import useWindowDimensions from "../../services/useWindowDimensions";

export function MenuItem({
  href,
  active,
  title,
  Icon,
  extra,
  collapseMenu = false,
}: {
  href: string;
  active?: boolean;
  title: string;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  extra?: React.ReactNode;
  collapseMenu?: boolean;
}) {

  if (collapseMenu) {
    return (
      <Link
        to={href}
        className={`p-4 hover:bg-secondary ${active ? "!bg-primary" : ""
          } w-full rounded-lg justify-start items-center flex`}
      >
        <Icon
          className={`w-6 h-6 relative ${active ? "[&_path]:stroke-white" : ""}`}
        />
        {extra}
      </Link>
    )
  }


  return (
    <Link
      to={href}
      className={`p-4 hover:bg-secondary ${active ? "!bg-primary" : ""
        } w-full rounded-lg justify-start items-center gap-2.5 flex`}
    >
      <Icon
        className={`w-6 h-6 relative ${active ? "[&_path]:stroke-white" : ""}`}
      />
      <div className={`${active ? "text-white" : ""} text-lg font-normal`}>
        {title}
      </div>
      {extra}
    </Link>
  );
}

export function MenuItemList({ count, collapseMenu, extraOpen, setExtraOpen }) {
  const pathname = window.location.pathname;
  const { myPermissions } = useAdminUsers()
  const { height, width } = useWindowDimensions();

  const menuItemList = [<MenuItem
    href="/dashboard"
    title="Dashboard"
    Icon={DashboardIcon}
    active={pathname === "/dashboard"}
    key="dashboard"
    collapseMenu={collapseMenu}
  />]

  if (myPermissions.some(p => [
    AdminPermission.READ_CHAT_CLIENT,
    AdminPermission.READ_CHAT_GENERAL_ADMIN,
    AdminPermission.READ_CHAT_INS_OPS_ADMIN,
    AdminPermission.READ_CHAT_RBT_ADMIN,
    AdminPermission.READ_CHAT_RBT_ADMIN
  ].includes(p))) {
    menuItemList.push(<MenuItem
      href="/messages"
      title="Messages"
      key="messages"
      Icon={MessageIcon}
      active={pathname === "/messages"}
      extra={
        count ? (
          <div
            className={`w-7 h-5 ${pathname === "/messages"
              ? "text-primary bg-white"
              : "text-white bg-primary"
              } rounded-3xl flex-col justify-start items-center gap-2.5 flex`}
          >
            <div className="text-sm font-normal">{count}</div>
          </div>
        ) : null
      }
      collapseMenu={collapseMenu}
    />)
  }

  if (myPermissions.includes(AdminPermission.READ_PAGE_MANAGE_CLIENTS)) {
    menuItemList.push(<MenuItem
      href="/clients"
      key="clients"
      title="Manage Clients"
      Icon={UsersIcon}
      active={pathname === "/clients"}
      collapseMenu={collapseMenu}
    />)
  }

  if (myPermissions.includes(AdminPermission.READ_PAGE_MANAGE_REFERRALS)) {
    menuItemList.push(<MenuItem
      href="/client-referrals"
      title="Manage Referrals"
      key="referrals"
      Icon={ReferralIcon}
      active={pathname === "/client-referrals"}
      collapseMenu={collapseMenu}
    />)
  }

  if (myPermissions.includes(AdminPermission.READ_PAGE_MANAGE_PROVIDERS)) {
    menuItemList.push(<MenuItem
      href="/providers"
      title="Manage Providers"
      key="providers"
      Icon={PersonIcon}
      active={pathname === "/providers"}
      collapseMenu={collapseMenu}
    />)
  }
  if (myPermissions.includes(AdminPermission.READ_PAGE_MANAGE_AUTH_REQUEST)) {
    menuItemList.push(<MenuItem
      href="/auth-requests"
      key="auth-requests"
      title="Auth Requests"
      Icon={ListTicksIcon}
      active={pathname === "/auth-requests"}
      collapseMenu={collapseMenu}
    />)
  }
  if (myPermissions.includes(AdminPermission.READ_PAGE_MANAGE_RBT)) {
    menuItemList.push(<MenuItem
      href="/rbt-requests"
      key="rbt-requests"
      title="RBT Requests"
      Icon={ListIcon}
      active={pathname === "/rbt-requests"}
      collapseMenu={collapseMenu}
    />)
    menuItemList.push(<MenuItem
      href="/rbt-candidates"
      key="rbt-candidates"
      title="Manage RBTs"
      Icon={UsersIcon}
      active={pathname === "/rbt-candidates"}
      collapseMenu={collapseMenu}
    />)
  }

  if (myPermissions.includes(AdminPermission.READ_PAGE_MANAGE_BULLETIN)) {
    menuItemList.push(<MenuItem
      href="/bulletin"
      key="bulletin"
      title="Manage Bulletin"
      Icon={BulletinIcon}
      active={pathname === "/bulletin"}
      collapseMenu={collapseMenu}
    />)
  }

  if (myPermissions.includes(AdminPermission.READ_PAGE_MANAGE_FORMS)) {
    menuItemList.push(<MenuItem
      href="/surveys"
      key="surveys"
      title="Manage Surveys"
      Icon={SurveyIcon}
      active={pathname === "/surveys"}
      collapseMenu={collapseMenu}
    />)
  }

  if (myPermissions.includes(AdminPermission.READ_PAGE_MANAGE_LIBRARY)) {
    menuItemList.push(<MenuItem
      href="/library"
      key="library"
      title="Manage Library"
      Icon={FolderIcon}
      active={pathname === "/library"}
      collapseMenu={collapseMenu}
    />)
  }


  if (collapseMenu) {
    return <div className="w-full flex-col justify-start items-start flex">{menuItemList}</div>;
  }

  let maxNum = ((height - 265) / 65) < 10 ? Math.floor(((height - 265) / 65)) : 9

  if (menuItemList.length <= (maxNum + 1) || maxNum === 0) {
    return (
      <div className="w-full flex-col justify-start items-start gap-2.5 flex">
        {menuItemList}
      </div>
    );
  }

  const overflowItems = menuItemList.splice(maxNum)

  return (
    <div className="w-full flex-col justify-start items-start gap-2.5 flex">
      {!extraOpen && menuItemList}

      <div
        className={`p-4 hover:bg-secondary cursor-pointer border w-full rounded-lg justify-start items-center gap-2.5 flex`}
        onClick={() => setExtraOpen(!extraOpen)}
      >
        {extraOpen ? <div className="w-6 h-6" /> : <More
          className={`w-6 h-6 relative ${extraOpen ? "[&_path]:stroke-white" : ""}`}
        />}
        <div className={`text-lg font-normal`}>
          {extraOpen ? "Collapse Other" : "Manage Other"}
        </div>
        {extraOpen && <div style={{ transform: 'translateX(20px)' }}><CloseIcon className={`w-6 h-6 relative`} /> </div>}
      </div>
      {extraOpen && overflowItems}
    </div>
  );


}

export function Menu() {
  const user = getUser();
  const { myPermissions, initialPermissionsLoaded } = useAdminUsers()
  const { userInfo = {}, totalUnreadConvCount } = useUser() as any;
  const [openDropdown, setOpenDropdown] = useState(false);
  const [logoutConfirm, setLogoutConfirm] = useState(false);
  const [settingsModal, setSettingsModal] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [collapseMenu, setCollapseMenu] = useState(true);
  const [extraOpen, setExtraOpen] = useState(false);

  const handleLogoutConfirm = () => {
    setLogoutConfirm(false);
    localStorage.clear();
    window.location.href = "/login";
  };

  const handleLogout = () => {
    setOpenDropdown(false);
    setLogoutConfirm(true);
  };

  const handleSettings = () => {
    setOpenDropdown(false);
    setSettingsModal(true);
  };

  const handleSettingsClose = () => {
    setSettingsModal(false);
  };

  if (!initialPermissionsLoaded) {
    return null
  }

  return (
    <>
      <div className={`${collapseMenu ? 'w-[80px]' : 'w-[264px]'} flex-col bg-white justify-start items-start flex`}>
        <div className="px-7 py-6 w-full border-b border-gray-200 flex-col justify-center items-start gap-2.5 flex">
          {collapseMenu ? <RightCollapseIcon className="w-7 h-7 cursor-pointer inline-block [&_path]:stroke-primary" onClick={() => setCollapseMenu(false)} /> : <div className="inline-block">
            <Logo className="w-[143px] h-7 inline-block" />
            <LeftCollapseIcon className="ml-9 w-7 h-7 cursor-pointer inline-block [&_path]:stroke-primary" onClick={() => { setCollapseMenu(true); setExtraOpen(false) }} />

          </div>}
        </div>
        <div className="self-stretch grow shrink basis-0 p-3 bg-white border-b border-black border-opacity-10 backdrop-blur-[27px] flex-col justify-start items-start gap-96 flex">
          <div className="self-stretch grow shrink basis-0 flex-col justify-between items-center flex">
            <div className="w-full flex-col justify-center items-start gap-1 flex">
              <MenuItemList count={totalUnreadConvCount} collapseMenu={collapseMenu} extraOpen={extraOpen} setExtraOpen={setExtraOpen} />
            </div>
            <div className="block relative w-full">
              <UserButton
                className="w-full [&>div]:!mx-0 !mb-0"
                variant="sideMenu"
                user={{ ...user, ...userInfo }}
                onClick={() => { setCollapseMenu(false); setOpenDropdown(true) }}
                collapseMenu={collapseMenu}
              />
              <Dropdown
                padding="15px"
                className="w-full !top-[-110px] !justify-start !items-start flex"
                isOpen={openDropdown}
                setIsOpen={() => setOpenDropdown(false)}
              >

                <button
                  className="h-8 text-left text-base w-full"
                  onClick={() => setShowChangePassword(true)}
                >
                  Change Password
                </button>

                {myPermissions.includes(AdminPermission.MANAGE_ADMIN) &&
                  <Link to={"/manage-admin"}>
                    <button
                      className="text-black hover:text-black/80 h-8 text-left text-base w-full"
                      onClick={() => setOpenDropdown(false)}
                    >
                      Manage Admin
                    </button>
                  </Link>
                }
                {/* <button
                  className="text-black hover:text-black/80 h-8 text-left text-base w-full mb-2"
                  onClick={handleSettings}
                >
                  Settings
                </button> */}
                <button
                  className="text-[#E3270E] h-8 text-left text-base w-full"
                  onClick={handleLogout}
                >
                  Logout
                </button>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>

      <AnimatePresence mode="wait" initial={false}>
        {showChangePassword && (
          <ChangePassword onClose={() => setShowChangePassword(false)} user={user} />
        )}
      </AnimatePresence>

      <AnimatePresence mode="wait" initial={false}>
        {logoutConfirm && (
          <LogoutConfirm
            onCancel={() => setLogoutConfirm(false)}
            onConfirm={handleLogoutConfirm}
          />
        )}
      </AnimatePresence>
      <AnimatePresence mode="wait" initial={false}>
        {settingsModal && <SettingsModal onClose={handleSettingsClose} />}
      </AnimatePresence>
    </>
  );
}
