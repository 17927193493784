import { ReactComponent as CheckIcon } from "../../../assets/icons/check.svg";
import { ReactComponent as LocationIcon } from "../../../assets/icons/location.svg";
import { ReactComponent as MessageIcon } from "../../../assets/icons/message.svg";
import { Button } from "../../../components/Button";
import { useEffect, useMemo, useState } from "react";
import { useUser } from "../../auth/context/user-context";
import { api } from "../../../services/api";
import { useNavigate } from "react-router-dom";
import { ShowToast } from "../../../services/toast";
import { ProviderDeleteConfirm } from "../../dashboard/components/ProviderDeleteConfirm";
import { BlockProviderConfirm } from "../components/BlockProviderConfirm";
import { ResendProviderEmailConfirm } from "../components/ResendProviderEmailConfirm";
import { ImageView } from "../../../components/Image";
import { getInitial } from "../../../services/utils";
import { ProviderAboutView } from "../../providers/components/ProviderAboutView";
import { ClientList } from "../../providers/components/ClientList";
import useClient from "../../providers/hooks/useClient";
import { zipCodes } from "../../rbtRequests/modals/zip_codes";
import { useAdminUsers } from "../../manageAdmin/context/AdminUsersContext";
import { AdminPermission } from "../../manageAdmin/AdminUserList.types";

const filterUsers = (users, keyword) => {
  if (!keyword) return users || [];
  return (
    users?.filter(
      (user) =>
        user.firstName?.toLowerCase().includes(keyword.toLowerCase()) ||
        user.lastName?.toLowerCase().includes(keyword.toLowerCase()) ||
        user.guardianFirstName?.toLowerCase().includes(keyword.toLowerCase()) ||
        user.guardianLastName?.toLowerCase().includes(keyword.toLowerCase()) ||
        user.mobile?.toLowerCase().includes(keyword.toLowerCase()) ||
        user.email?.toLowerCase().includes(keyword.toLowerCase())
    ) || []
  );
};

export function ProviderProfileView({ onClose, provider, onEdit, onRefresh }) {
  const navigate = useNavigate();
  const { myPermissions } = useAdminUsers();
  const [activeTab, setActiveTab] = useState("about"); // ["about", "clients"]
  const [loading, setLoading] = useState(false);
  const [removeConfirmModal, setRemoveCategoryModal] = useState<any>(null);
  const [resendEmailConfirmModal, setResendEmailConfirmModal] = useState<any>(null);
  const [blockConfirmModal, setBlockConfirmModal] = useState<any>(null);


  const { setSelectedProviderToMessage, userInfo } = useUser() as any;

  const { updateFilters, filters, users, total, isLoading, loadMore } =
    useClient();

  const handleMessage = async () => {
    // check if user is logged in

    // redirect to messaging page
    // create schedule appointment
    const payload = {
      providerId: provider.id,
      patientId: userInfo?.id,
    };
    try {
      setLoading(true);
      const response = await api.scheduleAppointment(payload);
      setLoading(false);
      if ([200, 201].includes(response.status)) {
        setSelectedProviderToMessage(provider);
        navigate("/messages");
      }
    } catch (error: any) {
      setLoading(false);
      if (error.response?.data?.message === "Already scheduled consultation") {
        setSelectedProviderToMessage(provider);
        navigate("/messages");
      } else {
        ShowToast({
          message:
            error.response.data.message ||
            "Unable to schedule appointment, please try again later",
          type: "error",
        });
      }
    }
  };

  useEffect(() => {
    updateFilters({ provider_id: provider.id });
  }, [provider.id]);

  const isZipValid = (code: string) => code && (code.trim().length === 5 && !isNaN(parseInt(code, 10)) && !!zipCodes[code])
  const [zipCity, zipState] = useMemo(() => {
    return isZipValid(provider.addressZip) ? [zipCodes[provider.addressZip]?.city, zipCodes[provider.addressZip]?.state] : [undefined, undefined];
  }, [provider.addressZip]);

  return (
    <>
      <div className="max-w-[820px] mx-auto flex flex-col mb-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <span className="text-black text-2xl font-bold">
              {provider?.firstName}'s Profile
            </span>
          </div>
          {myPermissions.includes(AdminPermission.WRITE_CHAT_CLIENT) && (
            <Button
              onClick={handleMessage}
              variant="primary"
              className="text-primary !rounded-full text-sm font-medium"
              disabled={loading}
              loading={loading}
            >
              <MessageIcon className="[&_path]:stroke-white" />
              Message
            </Button>
          )}
        </div>
      </div>
      <div
        id="provider-scroll"
        className="max-h-[calc(100vh-150px)] lg:max-h-[calc(100vh-100px)] overflow-auto"
      >
        <div className="max-w-[820px] mx-auto flex flex-col gap-4">
          <>
            <div className="flex p-5 bg-white rounded-2xl gap-2 flex-col">
              <div className="items-start md:items-center gap-6 flex">
                {provider.profileImg ? (
                  <ImageView
                    className="w-32 h-32 rounded-2xl object-cover"
                    src={provider.profileImg || "/images/profile1.png"}
                    alt="provider"
                    loading="lazy"
                  />
                ) : (
                  <div
                    className={`uppercase w-32 h-32 rounded-2xl flex justify-center items-center text-[40px] text-primary bg-secondary`}
                  >
                    {getInitial(provider?.firstName, provider?.lastName)}
                  </div>
                )}
                <div className="flex-1 justify-between gap-2 md:gap-1 items-start md:items-center flex flex-col md:flex-row">
                  <div className="flex-col justify-start items-start gap-3.5 inline-flex">
                    <div className="flex-col justify-start items-start gap-2.5 flex">
                      <div className="justify-start items-start gap-2.5 flex flex-col md:flex-row">
                        <div className="text-black text-2xl font-semibold">
                          {provider.firstName} {provider.lastName}
                        </div>
                        {provider?.isAvailable && (
                          <div className="pl-2.5 pr-3 py-1.5 bg-white rounded-3xl border border-primary border-opacity-25 justify-start items-center gap-0.5 flex">
                            <CheckIcon className="w-4 h-4" />
                            <div className="text-black text-sm font-normal ">
                              Accepting New Clients
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="text-black text-base font-normal ">
                        {provider?.profession?.join(", ")}
                      </div>
                    </div>
                    <div className="justify-start items-start gap-1.5 inline-flex">
                      <LocationIcon className="w-6 h-6 justify-center items-center flex" />
                      <div className="text-black text-base font-normal ">
                        {provider?.addressZip && `${zipCity}, ${zipState} (${provider?.addressZip})`}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2.5 items-end">
                    {myPermissions.includes(AdminPermission.WRITE_PAGE_MANAGE_PROVIDERS) && <>
                      <Button
                        variant="link"
                        className="py-[14px] px-6 !rounded-full"
                        onClick={onEdit}
                      >
                        <div className="!text-primary !text-sm font-medium hover:!text-black">
                          Edit Profile
                        </div>
                      </Button>
                      <Button
                        variant="link"
                        className="py-[14px] px-6 !rounded-full"
                        onClick={() => {
                          setResendEmailConfirmModal(provider);
                        }}
                      >
                        <div className="!text-sm font-medium hover:!text-black">
                          Resend Invite Email
                        </div>
                      </Button>
                      <Button
                        variant="link"
                        className="py-[14px] px-6 !rounded-full"
                        onClick={() => {
                          setBlockConfirmModal(provider);
                        }}
                      >
                        <div className="!text-[#BB2828] !text-sm font-medium hover:!text-black">
                          Deactivate
                        </div>
                      </Button>
                    </>}
                  </div>
                </div>
              </div>
            </div>
          </>
          <div
            className={`w-fit p-1.5 bg-white rounded-3xl justify-start items-start gap-px inline-flex`}
          >
            <button
              onClick={() => setActiveTab("about")}
              className={`px-4 py-2 ${activeTab === "about" ? "bg-green-100" : "bg-white"
                } rounded-full justify-center items-center gap-2.5 flex`}
            >
              <div className="text-black text-base font-normal font-['Outfit'] leading-tight">
                About
              </div>
            </button>
            <button
              onClick={() => setActiveTab("clients")}
              className={`px-4 py-2 ${activeTab === "clients" ? "bg-green-100" : "bg-white"
                } rounded-full justify-center items-center gap-2.5 flex`}
            >
              <div className="text-black text-base font-normal leading-tight flex items-center">
                Clients{" "}
                {isLoading ? (
                  <>
                    <div className="w-4 h-4 ml-1.5 mt-1 rounded-md bg-gray-300 animate-pulse transition duration-50"></div>
                  </>
                ) : (
                  <>({filterUsers(users, filters.keyword).length})</>
                )}
              </div>
            </button>
          </div>
          {activeTab === "about" && <ProviderAboutView provider={provider} />}
          {activeTab === "clients" && (
            <ClientList
              providerId={provider.id}
              updateFilters={updateFilters}
              filters={filters}
              users={filterUsers(users, filters.keyword)}
              total={total}
              isLoading={isLoading}
              loadMore={loadMore}
            />
          )}
        </div>
      </div>

      {removeConfirmModal && (
        <ProviderDeleteConfirm
          onCancel={() => setRemoveCategoryModal(null)}
          onSuccess={() => {
            onRefresh(removeConfirmModal.id, true);
            setRemoveCategoryModal(null);
            onClose();
          }}
          user={removeConfirmModal}
        />
      )}
      {blockConfirmModal && (
        <BlockProviderConfirm
          onCancel={() => setBlockConfirmModal(null)}
          onSuccess={() => {
            onRefresh(blockConfirmModal.id, true);
            setBlockConfirmModal(null);
            onClose();
          }}
          user={blockConfirmModal}
        />
      )}
      {resendEmailConfirmModal && (
        <ResendProviderEmailConfirm
          onCancel={() => setResendEmailConfirmModal(null)}
          onSuccess={() => {
            setResendEmailConfirmModal(null)
            onClose();
          }}
          user={resendEmailConfirmModal}
        />
      )}
    </>
  );
}
