import { Routes, Route, Navigate } from "react-router-dom";
import { Main } from "./main";
import SuspenseWrapper from "../components/SuspenseWrapper";
import { Login } from "./auth/login/Login";
import { MailBoxProvider } from "./mailbox/context/mailbox-context";
import { Library } from "./Library/Library";
import { FileProvider } from "./Library/context/file-context";
import { FamilyList } from "./family/list/FamilyList";
import { Dashboard } from "./dashboard/Dashboard";
import { ProviderList } from "./provider/list/ProviderList";
import { AdminChat } from "./admin-chat/AdminChat";
import { BulletinList } from "./bulletin/list/BulletinList";
import { RbtRequestList } from "./rbtRequests/list/RbtRequestList";
import { RbtCandidateList } from "./rbtCandidates/list/RbtCandidateList";
import { AuthRequestList } from "./authRequests/list/AuthRequestList";
import { ClientReferralList } from "./clientReferrals/list/ClientReferralList";
import { AdminUserList } from "./manageAdmin/list/AdminUserList";
import { FormList } from "./forms/list/FormList";

const RoutesList = () => {
  return (
    <Routes>
      <Route path="/">
        <Route path="login" element={<Login />} />
      </Route>
      <Route element={<Main />}>
        <Route
          path="/dashboard"
          element={
            <SuspenseWrapper>
              <MailBoxProvider>
                <Dashboard />
              </MailBoxProvider>
            </SuspenseWrapper>
          }
        />
        <Route
          path="/clients"
          element={
            <SuspenseWrapper>
              <FamilyList />
            </SuspenseWrapper>
          }
        />
        <Route
          path="/client-referrals"
          element={
            <SuspenseWrapper>
              <FileProvider>
                <ClientReferralList />
              </FileProvider>
            </SuspenseWrapper>
          }
        />
        <Route
          path="/providers"
          element={
            <SuspenseWrapper>
              <ProviderList />
            </SuspenseWrapper>
          }
        />
        <Route
          path="/messages"
          element={
            <SuspenseWrapper>
              <MailBoxProvider>
                <AdminChat />
              </MailBoxProvider>
            </SuspenseWrapper>
          }
        />
        <Route
          path="/bulletin"
          element={
            <SuspenseWrapper>
              <MailBoxProvider>
                <BulletinList />
              </MailBoxProvider>
            </SuspenseWrapper>
          }
        />
        <Route
          path="/surveys"
          element={
            <SuspenseWrapper>
              <MailBoxProvider>
                <FormList />
              </MailBoxProvider>
            </SuspenseWrapper>
          }
        />
        <Route
          path="/library"
          element={
            <SuspenseWrapper>
              <FileProvider>
                <Library />
              </FileProvider>
            </SuspenseWrapper>
          }
        />
        <Route
          path="/auth-requests"
          element={
            <SuspenseWrapper>
              <FileProvider>
                <AuthRequestList />
              </FileProvider>
            </SuspenseWrapper>
          }
        />
        <Route
          path="/rbt-requests"
          element={
            <SuspenseWrapper>
              <FileProvider>
                <RbtRequestList />
              </FileProvider>
            </SuspenseWrapper>
          }
        />
        <Route
          path="/rbt-candidates"
          element={
            <SuspenseWrapper>
              <FileProvider>
                <RbtCandidateList />
              </FileProvider>
            </SuspenseWrapper>
          }
        />
        <Route
          path="/manage-admin"
          element={
            <SuspenseWrapper>
              <FileProvider>
                <AdminUserList />
              </FileProvider>
            </SuspenseWrapper>
          }
        />

        <Route
          index
          element={
            // if loggged in redirect to dashboard
            <Navigate to="/login" />
          }
        />
      </Route>
    </Routes>
  );
};
export default RoutesList;
