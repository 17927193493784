import { useCallback, useEffect, useState } from "react";
import { api } from "../../../services/api";
import { useAdminUsers } from "../../manageAdmin/context/AdminUsersContext";
import { getUser } from "../../../services/utils";
import { AdminPermission } from "../../manageAdmin/AdminUserList.types";
import { User } from "../../../services/user.types";

export type Form = {
  id: string;
  title: string;
  url: string;
  description?: string;
  createdAt: Date;
  updatedAt?: Date;
  isActive: boolean;
  assignedUsers?: Array<User>;
  completedBy?: Array<User>;
};

function useForms() {
  const user = getUser();
  const { myPermissions } = useAdminUsers();
  const [forms, setForms] = useState<Array<Form>>([]);

  const fetchForms = useCallback(async () => {
    if (myPermissions.includes(AdminPermission.READ_PAGE_MANAGE_FORMS)) {
      setForms(await api.getForms());
    }
  }, [myPermissions]);

  useEffect(() => {
    fetchForms();
  }, [user.id, myPermissions, fetchForms]);

  return {
    forms: forms,
    fetchForms: fetchForms,
  };
}

export default useForms;
