import { ReactComponent as CheckIcon } from "../../../assets/icons/check.svg";
import { Button } from "../../../components/Button";
import { useMemo, useState } from "react";
import { TextInput } from "../../../components/TextInput";
import { ShowToast } from "../../../services/toast";
import { api } from "../../../services/api";
import Modal from "../../../components/Modal";
import { MultiSelect } from "../../provider/components/MultiSelect";
import useProviders from "../../provider/hooks/useProviders";
import useForms, { Form } from "../hooks/useForms";
import { Loading } from "../../../components/Loading/Loading";
import { SurveyAgain } from "../components/SurveyAgainConfirm";

export function FormModal({
  isOpen,
  onClose,
  existingForm,
}: {
  isOpen: boolean;
  onClose: (val?: boolean) => void;
  existingForm?: Form
}) {
  const [errors, setErrors] = useState({} as any);
  const [saving, setSaving] = useState(false);
  const [surveyUserAgain, setSurveyUserAgain] = useState<any>(undefined);
  const { providers, isLoading } = useProviders();

  const [formLink, setFormLink] = useState<string>(existingForm ? existingForm.url : "");
  const [form, setForm] = useState<any>(existingForm ? {
    ...existingForm,
    assignedUsers: existingForm.assignedUsers?.map((user) => user.id) || []
  } : {
    title: "",
    url: "",
    assignedUsers: [],
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    if (name === 'url') {
      setFormLink(value)
      if (value.length > 0 && !isLinkValid(value)) {
        setErrors({ ...errors, url: "Invalid Google Form Link" })
        return
      } else {
        setErrors({ ...errors, url: "" })
      }
    }

    setForm((prev: any) => ({ ...prev, [name]: value }));
  };

  const isLinkValid = (link: string) => {
    const flags = "gmi";
    const pattern = "^https?:\/\/(docs\.)?google\.com\/forms/d/e/[a-z0-9-]+/viewform"
    const regexPattern = new RegExp(pattern, flags);
    return regexPattern.test(link)
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});
    setSaving(true);
    try {
      setErrors({});
      setSaving(true);

      if (existingForm) {
        const updateRes = await api.updateForm({
          ...existingForm,
          title: form.title,
          url: form.url,
          assignedUsers: form.assignedUsers,
        });
        if ([200, 201].includes(updateRes.status)) {
          ShowToast({
            type: "success",
            message: "Survey Updated",
          });
          onClose(true);
        }

      } else {
        const payload = {
          title: form.title,
          url: form.url,
          assignedUsers: form.assignedUsers,
        }
        const createBulletinRes = await api.createForm(payload);
        if ([200, 201].includes(createBulletinRes.status)) {
          ShowToast({
            type: "success",
            message: "Survey Created",
          });
          onClose(true);
        }
      }
      setSaving(false);
    } catch (error: any) {
      setSaving(false);
      return false;
    }
  };


  const disabled = useMemo(() => {
    return (
      !form.title ||
      !form.url ||
      !isLinkValid(formLink)
    );
  }, [form, formLink]);

  return (
    <Modal isOpen={isOpen} handleModal={() => onClose()}>
      <div className="max-w-[820px] mx-auto flex flex-col mb-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <span className="text-black text-2xl font-bold">{existingForm ? 'Edit' : 'Add'} Survey</span>
          </div>
          <Button
            variant="primary"
            className="!text-sm !font-medium !rounded-full"
            onClick={handleSubmit}
            disabled={disabled || saving}
            loading={saving}
          >
            <CheckIcon className="[&_path]:stroke-white" />
            {existingForm ? 'Save' : 'Add'}
          </Button>
        </div>
      </div>
      <div className="w-full max-h-[calc(100vh-160px)] lg:max-h-[calc(100vh-100px)] overflow-auto" id="provider-scroll">
        <div className="max-w-[820px] mx-auto flex flex-col gap-4">
          <div className="pt-flex pt-5 px-5 bg-white rounded-2xl flex-col">
            <div className="flex flex-col md:flex-row items-center justify-center gap-3 mb-5">
              <div className="flex-1 w-full flex flex-col gap-1.5">
                <label className="text-sm text-black/80">Title</label>
                <TextInput
                  name="title"
                  value={form.title}
                  onChange={handleChange}
                  placeholder="Enter your new Survey Title"
                  maxLength={250}
                />
              </div>
            </div>
          </div>

          <div className="flex p-5 bg-white rounded-2xl flex-col gap-4">
            <label className="text-sm text-black/80">Google form responder link</label>
            <TextInput
              placeholder="Enter Google Form Responder Link URL (e.g. https://docs.google.com/forms/d/123.../viewform)"
              className="!p-[14px]"
              name="url"
              value={formLink}
              onChange={handleChange}
            />
            {errors.url && (
              <div className="text-red-500 text-xs font-medium">
                {errors.url}
              </div>
            )}

          </div>

          <div className="flex p-5 bg-white rounded-2xl flex-col gap-4">
            <label className="text-sm text-black/80">Providers to complete survey:
              <div className="ml-3 mr-1 inline-block text-primary cursor-pointer" onClick={() => setForm({ ...form, assignedUsers: providers.map(p => p.id) })}>Select All</div> /
              <div className="ml-1 inline-block text-primary cursor-pointer" onClick={() => setForm({ ...form, assignedUsers: [] })}>Deselect All</div>
            </label>
            {(providers.length === 0 || isLoading) ? <div className="mb-4 mx-auto w-5 h-5"><Loading /></div> : <>
              <MultiSelect
                disabled={saving}
                options={providers.map(provider => ({ label: provider.firstName + " " + provider.lastName, value: provider.id }))}
                value={form.assignedUsers || []}
                name="assignedUsers"
                onChange={(selectedOptions) => {
                  setForm((prev: any) => ({
                    ...prev,
                    assignedUsers: selectedOptions.map((o) => o.value),
                  }));
                }}
              />
              {errors.assignedUsers && (
                <div className="text-red-500 text-xs font-medium">
                  {errors.assignedUsers}
                </div>
              )}
            </>}

          </div>

          {existingForm && <div className="flex p-5 bg-white rounded-2xl flex-col gap-4">
            <label className="text-sm text-black/80">Survey completed by:</label>
            {(providers.length === 0 || isLoading) ? <div className="mb-4 mx-auto w-5 h-5"><Loading /></div> : <>
              <div className="text-gray-500 text-sm">
                {form.completedBy?.length === 0 ? "No providers have completed this survey yet." :
                  form.completedBy.map(provider =>
                    <span className="text-primary cursor-pointer" onClick={() => setSurveyUserAgain(provider)}>{provider.firstName} {provider.lastName}</span>
                  )
                }
              </div>
            </>}
          </div>}
        </div>
      </div>
      {surveyUserAgain && existingForm && <SurveyAgain
        onCancel={() => setSurveyUserAgain(undefined)}
        onSuccess={() => {
          setForm({ ...form, completedBy: form.completedBy.filter((user) => user.id !== surveyUserAgain.id) })
          setSurveyUserAgain(undefined);
        }}
        form={existingForm}
        user={surveyUserAgain}
      />}
    </Modal >
  );
}
